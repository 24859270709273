import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { Box, CircularProgress, TextField} from '@mui/material';
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { NFTStorage, File } from "nft.storage"
import fs from 'fs'
import dotenv from 'dotenv'
import FadeIn from 'react-fade-in';
import { SocialIcon } from 'react-social-icons';
import "./styles.css";
import ConnectButton from './ConnectButton.js'
import logo from './styles/logo.png'
import bg from './styles/bg.png'
import { margin } from "@mui/system";
import { ethers } from "ethers";
dotenv.config()

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

  


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState("")
  const data = useSelector((state) => state.data);
  const [mintAmount, setMintAmount] = useState(1);
  const [feedback, setFeedback] = useState('')
  const [totalSupply, setTotalSupply] = useState()
  const [claimingNft,setClaimingNft] = useState(false)
  const [connectText, setConnectText] = useState('CONNECT')
  
    // Create a reference for totalSupply
  const totalSupplyRef = useRef(data.totalSupply);

  const connectBut = () => {
    setIsConnected(true);
    setConnectText('DISCONNECT');
  };

  const getTotalSupply = () => {
    const totalSupplys = blockchain.smartContract.methods.totalSupply().call();
    setTotalSupply(totalSupplys);
  };


  const [CONFIG, SET_CONFIG] = useState({
    
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  useEffect(async () => {
    // Fetch data when component mounts and when the account changes
    await dispatch(fetchData(blockchain.account));
  }, [blockchain.account]);

  
  
  const mint = async () => {
    try {
        const totalSupply = await blockchain.smartContract.methods.totalSupply().call();
        const cost = calculateDynamicPrice(totalSupply, mintAmount);

        setClaimingNft(true);
        setFeedback(`Proceeding to mint...`);

        await blockchain.smartContract.methods
            .mint(mintAmount)
            .send({
                value: cost.toString(),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong. Please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                setFeedback();
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    } catch (error) {
        console.error("An error occurred:", error);
    }
};

  
  

  const getData = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }

  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  
  useEffect(async() => {
    const getConfigAndData = async () => {
      await getConfig();
      await getData();
    };
    getConfigAndData();
    
  }, []);


  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    
      if (newMintAmount > 2) {
        newMintAmount = 2;
      }
    
    setMintAmount(newMintAmount);
  };

  const getCost = () => {
    
    const costWei = calculateDynamicPrice(data.totalSupply, mintAmount);
    const costEth = ethers.utils.formatEther(costWei);
    return Number(costEth);
};


  const calculateDynamicPrice = (currentSupply, mintQty) => {
    console.log("Current Total Supply:", data.totalSupply);
    const numCurrentSupply = Number(currentSupply);

    let totalCost = ethers.BigNumber.from("0");
    for (let i = 0; i < mintQty; i++) {
        if (numCurrentSupply + i < 250) {
            totalCost = totalCost.add(ethers.BigNumber.from("0"));
        } else if (numCurrentSupply + i < 270) {
            totalCost = totalCost.add(ethers.utils.parseEther("1"));
        } else if (numCurrentSupply + i < 350) {
            totalCost = totalCost.add(ethers.utils.parseEther("10"));
        } else if (numCurrentSupply + i < 450) {
            totalCost = totalCost.add(ethers.utils.parseEther("100"));
        } else if (numCurrentSupply + i < 550) {
            totalCost = totalCost.add(ethers.utils.parseEther("1000"));
        } else if (numCurrentSupply + i < 750) {
            totalCost = totalCost.add(ethers.utils.parseEther("10000"));
        } else {
            totalCost = totalCost.add(ethers.utils.parseEther("100000"));
        }
    }
    console.log("Total cost:", totalCost.toString());
    return totalCost;
};

  
    return (
      
      <div className="App">
            
          <div className="container">
            <img className="bg" src={bg} alt='background'></img>
          <h2 className="title"><br></br>Rock Punks</h2>
          <h2 className="paragraph"><br></br>MINT</h2>
          {isConnected ?   <div>
            
          <div className="supply">
            <div className="supply-container"><p className="paragraph2">{data.totalSupply} / {CONFIG.MAX_SUPPLY}</p></div>
            <div className="supply-container2"><p className="paragraph2" style={{marginBottom: '50px'}}>Price: {getCost()} BROCK</p></div>
          </div>
          <div className="mintAmount">{mintAmount}</div>
          <button className="increment" onClick={(e) => {
                                e.preventDefault();
                                decrementMintAmount();
                              }}>-</button>
          <button className="button" disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {

                              
                                e.preventDefault();
                                mint();
                                getData();
                              }}
                            >
                              {claimingNft ? <p className="button-text">LOADING</p> :<p className="button-text">MINT NOW</p>}</button>
          <button className="increment" onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                              }}>+</button>
          </div> : <button className="button" type="submit"  onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect());
                                getData();
                                connectBut(true)
                              }}> {connectText}
              </button>}
            </div>
            <div>
            
            </div>
           
     </div>
    
    );
  }


export default App;
